import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { handleSubmit as formSubmitter } from "../../../libs/form-submitter";
import Cookies from "js-cookie";

import "../styles/contactForm.scss";
import '../styles/spinner.scss';

function ContactForm({ submitCampaignID, submitType, customBg, warningTxt }) {

  /* Get GAUID */
  const clientId = typeof document !== "undefined" ? Cookies.get('_ga') : null;
  const gaUID = ((clientId != null) || (clientId != undefined)) ? clientId.substring(6) : "No GAUID Detected";

  /* Get URL Params */
  const urlRef = typeof window !== 'undefined' ? window.location.search : '';
  const url = urlRef.slice(1);
  const urlParams = new URLSearchParams(url);
  const utm_source = urlParams.has("utm_source") ? urlParams.get("utm_source") : 'NoSource';
  const utm_medium = urlParams.has("utm_medium") ? urlParams.get("utm_medium") : 'NoMedium';
  const utm_campaign = urlParams.has("utm_campaign") ? urlParams.get("utm_campaign") : 'NoCampaign';
  const utm_term = urlParams.has("utm_term") ? urlParams.get("utm_term") : 'NoTerm';
  const utm_content = urlParams.has("utm_content") ? urlParams.get("utm_content") : "NoContent";
  const utm_gclid = urlParams.has("gclid") ? urlParams.get("gclid") : "NoGCLID";

  /* Get Initial Cookie Value */
  let muSource = Cookies.get('_muSource'); 
  let muMedium = Cookies.get('_muMedium');
  let muCampaign = Cookies.get('_muCampaign'); 
  let muTerm = Cookies.get('_muTerm');
  let muContent = Cookies.get('_muContent');
  
  /* Set Cookie Value and Store for Logic App Values */
  let source;
  let medium;
  let campaign;
  let term;
  let content;
  source = ( muSource === undefined ? ( Cookies.set('_muSource', utm_source, { expires: 7 }), source = Cookies.get('_muSource') ) : ( muSource === "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( muSource !== "NoSource" && utm_source !== "NoSource" ? ( Cookies.set('_muSource', utm_source, { expires: 7 } ), source = Cookies.get('_muSource') ) : ( source = muSource ) ) ) );
  medium = ( muMedium === undefined ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 }), medium = Cookies.get('_muMedium') ) : ( muMedium === "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( muMedium !== "NoMedium" && utm_medium !== "NoMedium" ? ( Cookies.set('_muMedium', utm_medium, { expires: 7 } ), medium = Cookies.get('_muMedium') ) : ( medium = muMedium ) ) ) );
  campaign = ( muCampaign === undefined ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 }), campaign = Cookies.get('_muCampaign') ) : ( muCampaign === "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( muCampaign !== "NoCampaign" && utm_campaign !== "NoCampaign" ? ( Cookies.set('_muCampaign', utm_campaign, { expires: 7 } ), campaign = Cookies.get('_muCampaign') ) : ( campaign = muCampaign ) ) ) );
  term = ( muTerm === undefined ? ( Cookies.set('_muTerm', utm_term, { expires: 7 }), term = Cookies.get('_muTerm') ) : ( muTerm === "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( muTerm !== "NoTerm" && utm_term !== "NoTerm" ? ( Cookies.set('_muTerm', utm_term, { expires: 7 } ), term = Cookies.get('_muTerm') ) : ( term = muTerm ) ) ) );
  content = ( muContent === undefined ? ( Cookies.set('_muContent', utm_content, { expires: 7 }), content = Cookies.get('_muContent') ) : ( muContent === "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( muContent !== "NoContent" && utm_content !== "NoContent" ? ( Cookies.set('_muContent', utm_content, { expires: 7 } ), content = Cookies.get('_muContent') ) : ( content = muContent ) ) ) );

  const formik = useFormik({
    initialValues: {
      submitFirstName: "",
      submitLastName: "",
      submitEmail: "",
      submitCampaignID,
      submitType,
      submitHowHear: 'Other',
      submitUTMSource: source,
      submitUTMMedium: medium,
      submitUTMCampaign: campaign,
      submitUTMTerm: term,
      submitUTMContent: content,
      submitGCLID: utm_gclid,
      submitUUID: gaUID,
      submitForm: "Contact Base Form",
    },

    validationSchema: Yup.object({
      submitFirstName: Yup.string()
        .max(15, "Must be 15 characters or less")
        .required("Required"),
      submitLastName: Yup.string()
        .max(20, "Must be 20 characters or less")
        .required("Required"),
      submitEmail: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      submitUUID: Yup.string(),
      submitHowHear: Yup.string(),
    }),

    onSubmit: () => formSubmitter(formValues, submitConfig),
  });

  const submitConfig = {
    preferThankYou: true,
    thankYouType: "rfi",
    thankYouForm: "shortform-rfi",
  };

  const formValues = formik.values;

  const [isActive, setActive] = useState("false");
  const ToggleClass = () => {
      setActive(!isActive); 
  };

  return (
    <div>
      <div id="submitting" className={isActive ? null : "show" }>
          <Row className="justify-content-center align-items-center">
              <Col xs={12} className="p-3 p-lg-4 text-center">
                  <h2 className="submitting-title">Submitting <FontAwesomeIcon icon={faSpinner} className="fa-spin" size="2x" /></h2>
              </Col>
          </Row>
      </div>
      <div id="bottomContactForm" className={customBg}>
        <Container fluid="lg">
          <Row className="align-items-center justify-content-center">
            <Col
              xl={5}
              lg={6}
              md={7}
              xs={12}
              className="contact-form text-center"
            >
              <h1 className="mb-3">Stay Updated</h1>
              <p>
                Sign up below to learn more and stay updated regarding Meridian
                University's graduate and public programs.
              </p>
              <Form onSubmit={formik.handleSubmit} className="px-3 px-md-0" id="form">
                <Form.Group controlId="namesGroup" className="names">
                  <Form.Group className="first">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      id="submitFirstName"
                      name="submitFirstName"
                      type="text"
                      placeholder="First Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.submitFirstName}
                      className={
                        formik.touched.submitFirstName && formik.errors.submitFirstName
                          ? `error`
                          : null
                      }
                    />
                    {formik.touched.submitFirstName &&
                    formik.errors.submitFirstName ? (
                      <div className="error-text">
                        {formik.errors.submitFirstName}
                      </div>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="last">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      id="submitLastName"
                      name="submitLastName"
                      type="text"
                      placeholder="Last Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.submitLastName}
                      className={formik.touched.submitLastName && formik.errors.submitLastName ? 
                        `error`
                        : null}
                    />
                    {formik.touched.submitLastName &&
                    formik.errors.submitLastName ? (
                      <div className="error-text">
                        {formik.errors.submitLastName}
                      </div>
                    ) : null}
                  </Form.Group>
                </Form.Group>
                <Form.Group className="email mb-4">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    id="submitEmail"
                    name="submitEmail"
                    type="email"
                    placeholder="Email Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.submitEmail}
                    className={formik.touched.submitEmail && formik.errors.submitEmail ? (
                      `error`
                    ) : null}
                  />
                  {formik.touched.submitEmail && formik.errors.submitEmail ? (
                    <div className="error-text">{formik.errors.submitEmail}</div>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="submitGCLID"
                    name="submitGCLID"
                    type="hidden"
                    value=""
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="submitUUID"
                    name="submitUUID"
                    type="hidden"
                    value=""
                    setfieldtouched="true"
                    shouldvalidate="false"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    id="submtHowHear"
                    name="submitHowHear"
                    type="hidden"
                    value="Other"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" onClick={ToggleClass} disabled={!(formik.isValid && formik.dirty)}>
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default ContactForm;
