import * as React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { StaticQuery, graphql, Link } from "gatsby"
import './styles/publicProgram.scss'

function UpcomingPublicEvent() {
    return (
        <StaticQuery
            query={graphql`
                query UpcomingPublicEvent {
                    allMdx(
                      filter: {frontmatter: {tags: {eq: "upcoming-event"}}}
                    ) {
                      nodes {
                        frontmatter {
                          headline
                          subheadline
                          subtitle
                          metadata {
                            LongDescription
                          }
                        }
                        slug
                      }
                    }
                }                 
            `}
            render={data => (
                <Row className="upcoming-courses">
                    <h1>Upcoming Public Programs and Events</h1>
                    {
                        data.allMdx.nodes.map(( node ) => (
                            <Col xs={12}>
                                <h2 className="mb-1" >{ node.frontmatter.headline }: { node.frontmatter.subheadline }</h2>
                                <h4 className="mb-2" >{ node.frontmatter.subtitle }</h4>
                                { node.frontmatter.metadata.LongDescription.split('\n').map(function(item, key) {
                                    return (
                                        <p key={key}>
                                            {item}
                                        </p>
                                    )
                                })}
                                <Link to={`/${ node.slug }`} className="btn btn-secondary">Learn more »</Link>
                                <hr />                               
                            </Col>
                        )
                    )}
                </Row>
            )}
        />
    )
}

export default UpcomingPublicEvent

